.p-tieredmenu {
  background-color: #2c3e50 !important;
}

.p-submenu-list {
  background-color: #2c3e50 !important;
}

.p-menuitem-active a {
  background-color: #2c3e50 !important;
}

.p-menuitem-link:hover {
  background-color: rgb(255, 255, 255, .1) !important;
}

.p-menuitem-text, .p-menuitem-icon, .p-submenu-icon {
  color: #fff !important;
}

.p-tieredmenu {
  border: none !important;
}

.p-tieredmenu {
  margin: 0;
  padding: 0;
  width: 100%;
}
