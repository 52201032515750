.p-menubar {
  background-color: #2c3e50 !important;
}

.p-menu-active a {
  background-color: #2c3e50 !important;
}

.p-menu-link:hover {
  background-color: rgb(255, 255, 255, .1) !important;
}

.p-menu-text, .p-menuitem-icon {
  color: #fff !important;
}

.p-menubar {
  border: none !important;
}

.p-menubar {
  margin: 0;
  padding: 0;
  width: 100%;
}

.p-menubar-root-list {
  background: #2c3e50 !important;
  color: #ffffff !important;
  width: 50% !important;
}

.p-menubar-button{
  background: #fff !important;
}
