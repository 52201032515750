html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.btn {
  margin-right: 5px;
}

.btn i {
  margin: 5px;
}

.form-elo {
  padding-bottom: 10px;
}

.p-inputgroup-addon {
  background-color: #647283 !important;
  color: #fff !important;
}

@media only screen and (min-width: 0px) and (max-width: 721px) {
  html, body {
    font-size: .5rem;
  }
}

@media only screen and (min-width: 722px) and (max-width: 1109px) {
  html, body {
    font-size: .6rem;
  }
}

@media only screen and (min-width: 1110px) and (max-width: 1210px) {
  html, body {
    font-size: .7rem;
  }
}

@media only screen and (min-width: 1211px) and (max-width: 1422px) {
  html, body {
    font-size: .8rem;
  }
}

@media only screen and (min-width: 1367) and (max-width: 1979) {
  html, body {
    font-size: .9rem;
  }
}

/*
@media only screen and (min-width: 0px) and (max-width: 1024px) {
  html, body {
    font-size: .5rem;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1366px) {
  html, body {
    font-size: .6rem;
  }
}

@media only screen and (min-width: 1367px) and (max-width: 1980px) {
  html, body {
    font-size: .7rem;
  }
}*/

.counter-item {
  text-align: center;
  z-index: 1;
  color: rgb(2, 2, 2); }
  .counter-item > i {
    display: block;
    font-size: 35px; }
  .counter-item .number {
    font-size: 50px;
    font-weight: 400;
    display: block; }
  .counter-item h4 {
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 16px; }


    .ui-menubar {
      border: 1px solid #aed0ea !important;
      background: #74b2e2 !important;
      color: #ffffff !important;
      font-weight: bolder !important;
  }

  .ui-menu-root-list {
    border: 1px solid #aed0ea !important;
    background: #74b2e2 !important;
    color: #ffffff !important;
    font-weight: bolder !important;
}

.p-datatable-tbody tr.p-rowgroup-header {
  background: rgb(160, 178, 151)!important;
}

.panelInfo {
  display: flex;
  justify-content: left;
  width: 25%;
  height: auto;
  position: fixed;
  z-index: 1000;
  top: 3rem;
  right: 2rem;
  background-color: yellow;
  border: 1px;
  border-color: black;
  border-radius: 10px;
}
